import { styled } from '@mui/material';
import { useTitle } from 'core/hooks';
import { useTranslation } from 'react-i18next';
import { route } from 'react-router-typesafe-routes/dom';

export const HelpPath = route('help');

const Iframe = styled('iframe')({
	flex: 1,
	border: 0,
});

export default function Help() {
	// Hooks
	const { t } = useTranslation('home');
	useTitle(`${t('help.title')} - ${t('title', { ns: 'core' })}`);

	return (
		<Iframe
			title={t('help.iframe.title')}
			src="https://scribehow.com/page-embed/ClearFleet_Training_Documents__YcCVXmXkTfmUbZTVM85HHQ"
			allowFullScreen
		/>
	);
}
